import React, { useRef, useEffect, useContext, useCallback } from "react";
import { ArticleContext } from "../context/ArticleContext";
import { ModalContext } from "../context/ModalContext";
import { CloseIcon, QuestionMarkIcon } from "../utils/Icons";

export default function ArticleDetails({ article }) {
  const { setSelectedArticle } = useContext(ArticleContext);
  const { openModal, closeModal, isModalOpen } = useContext(ModalContext);
  const detailsRef = useRef(null);
  const mobileSpacerRef = useRef(null);

  // Add spacing below last section when details are showing
  useEffect(() => {
    if (detailsRef.current && !isModalOpen) {
      mobileSpacerRef.current.style.height = `${detailsRef.current.offsetHeight}px`;
    } else {
      mobileSpacerRef.current.style.height = "0px";
    }
  }, [article, isModalOpen]);

  const handleCloseBtnClick = useCallback(() => {
    setSelectedArticle(null);
  }, [setSelectedArticle]);

  const handleQuestionMarkClick = () => {
    openModal(
      <>
        <h5 className="font-semibold text-xl">Article Summary</h5>
        <p className="text-sm text-justify">
          The summary is generated by Google's Gemini AI. It is meant to be a
          brief idea of what the article is about without taking away from the
          original author.
        </p>
        <p className="italic text-sm text-justify">
          Note: AI can be unpredictable. The details of the summary may not be
          accurate. Please see the full article for accurate information.
        </p>
      </>
    );
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        if (isModalOpen) {
          closeModal();
        } else {
          handleCloseBtnClick();
        }
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [handleCloseBtnClick, isModalOpen, closeModal]);

  return (
    <>
      <div
        id="article-details"
        ref={detailsRef}
        className={`fixed
        flex
        flex-col
        overflow-y-auto
        overflow-x-hidden
        w-[calc(100%-4px)]
        tablet:w-56
        desktop:w-72
        max-h-[calc(100vh-32px)]
        left-0
        tablet:left-[auto]
        desktop:left-[min(calc(100vw-320px),calc(50vw+700px))]
        right-0
        desktop:right-[auto]
        tablet:top-1/2
        bottom-0
        tablet:bottom-[auto]
        tablet:transform
        tablet:-translate-y-1/2
        p-2
        tablet:p-4
        tablet:m-4
        border-[1px]
        border-[var(--text-color)]
        tablet:rounded-lg
        bg-[var(--accent-color)]
        text-sm
        z-50
        `}
      >
        <button
          onClick={handleCloseBtnClick}
          className="absolute right-1 top-1 h-6 w-6"
          type="button"
          aria-label="Close article details"
        >
          <CloseIcon />
        </button>
        <h4 className="font-bold text-md tablet:text-lg desktop:text-xl mb-0 m-4">
          {article.headline}
        </h4>
        <a
          href={article.url}
          className="underline my-2 tablet:my-4"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read Article
        </a>
        <div className="bg-[var(--primary-color)] p-4 rounded-lg">
          <div className="flex gap-2">
            <p className="text-left font-semibold">Summary</p>
            <button
              type="button"
              aria-label="About summary"
              className="h-5 w-5"
            >
              <QuestionMarkIcon onClick={handleQuestionMarkClick} />
            </button>
          </div>
          <p className="text-justify text-sm desktop:text-base">
            {article.summary}
          </p>
        </div>
      </div>
      <div
        ref={mobileSpacerRef}
        id="mobile-spacer"
        className="w-0 tablet:!h-0 tablet:hidden"
      ></div>
    </>
  );
}
